<template>
	<div>
    <picture>
      <source srcset="/assets/modals/food_circular.webp" type="image/webp" media="(min-width: 700px)" />
      <source srcset="/assets/modals/food_circular.jpg" type="image/jpeg" media="(min-width: 700px)" />
      <source srcset="/assets/modals/food_circular_mobile.webp" type="image/webp" />
      <img class="img-fluid" src="/assets/modals/food_circular_mobile.jpg" :alt="$t('imgAlt')">
    </picture>

    <div class="red_bg"></div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <h1 class="text-center">{{ $t('title') }}</h1>
          <p v-for="(item, index) in $t('copy1')" v-html="item"></p>
          
          <ul>
            <li v-for="(item, index) in $t('list1')" v-html="item"></li>
          </ul>

          <p v-for="(item, index) in $t('copy2')" v-html="item"></p>

          <div class="row">

            <div v-for="(callout, index) in $t('callouts')" class="col-md-6">
              <div class="red_bg">
                <h3 class="text-center">{{ callout.title }}</h3>
              </div>

              <div class="icon_row" v-for="(item, index) in callout.list">
                <div class="icon_wrapper">
                  <img :src="'/assets/modals/food_circular_icon' + item[1] + '.png'">
                </div>
                <p>{{item[0]}}</p>
              </div>

            </div>

          </div>

          <p v-for="(item, index) in $t('copy3')" v-html="item"></p>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
export default {
  name: 'FoodCircular'
}
</script>

<style lang="scss" scoped>
div > picture:first-child {
  display: block;
  margin-top: -50px;
  width: 100%;
  max-width: none;
  img {
    width: 100%;
    max-width: none;
    display: block;
  }
  + .red_bg {
    padding: 25px 50px;
    margin-bottom: 50px;
  }
}

.col-md-6 .red_bg {
  padding: 15px 5px;
  margin-bottom: 20px;
  margin-top: 20px;
  h3 {
    color: #fff;
    margin-bottom: 0;
  }
}

.icon_row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;

  .icon_wrapper {
    flex-shrink: 0;
    flex-grow: 0;
    width: 60px;
    height: 60px;
    margin-right: 20px;
    text-align: center;

    img {
      margin-top: 0 !important;
      max-height: 90%;
      max-width: 90%;
      width: auto;
      margin: 0 auto;
    }
  }
}

ul {
  margin-bottom: 25px;
  margin-top: 20px;
}
</style>

<i18n>
{
  "en" : {
    "imgAlt" : "Man with two children looking at plants",
    "title" : "The Impact of a Circular Economy",
    "copy1" : [
      "Humanity must create a different kind of economy—a “circular” economy in which resources, once extracted, cycle through use and reuse for as long as possible; in which waste and pollution are eliminated; and in which natural systems are regenerated.",
      "For foodservice providers, the biggest sustainability challenges include:"
    ],
    "list1" : [
      "Reducing food waste",
      "Creating processes that contribute to the circular economy",
      "Decreasing carbon footprint and use of resource",
      "Responding to new food models such as food delivery and omni-channel ordering"
    ],
    "copy2" : [
      "A third or more of food that is produced is never eaten. More than half of that waste occurs during processing, distribution, and retail sales.* On a planet that’s home to hundreds of millions of hungry people, reducing food waste is a moral imperative. It’s also a good-business imperative.",
      "Despite margins that are already razor-thin, grocers, convenience stores, and restaurants routinely throw away three to four percent of their margin by accepting food waste as part of the cost of doing business.* Additionally, government policies and consumers are increasingly pressuring brands, restaurants, retailers, and producers to create sustainability within their supply chains. Avery Dennison Intelligent Food Industry Solutions give foodservice providers the tools to create, implement and maintain sustainable processes that reduce waste. With not only the environmental benefits but cost-savings across the supply chain, sustainable practices encourage consumer trust that can lead to an increase in sales.",
      "For more information on the importance of creating a circular economy in the food supply chain, contact us at 800.543.6650 or printersolutions@averydennison.com."
    ],
    "callouts" : [
      {
        "title" : "Benefits of Intelligent Labels RFID",
        "list" : [
          ["In the case of a food recall, the affected lot of food can accurately be traced and located to no more food than necessary is discarded", "1"],
          ["Track temperature throughout the shipping process to ensure product compliance", "2"],
          ["Manage accurate expiry time/dates for food safety", "3"],
          ["Prevent overstock or wrong stock in-store and efficiently manage inventory", "4"]
        ]
      },
      {
        "title" : "Benefits of Avery Dennison Automated Solutions",
        "list" : [
          ["Accurate, legible prep labels to ensure food is used before it expires", "5"],
          ["Constant monitoring of refrigeration units to prevent food spoilage", "6"],
          ["Track food waste in the kitchen to target problem areas and save money", "7"],
          ["Quick, easy food donation and tracking for sustainability and tax benefits", "8"]
        ]
      }
    ],
    "copy3" : [
      "Food Foolish: The Hidden Connection Between Food Waste Hunger and Climate Change."
    ]
  },
  "fr" : {
    "imgAlt" : "Man with two children looking at plants",
    "title" : "Impact de l’économie circulaire/durable",
    "copy1" : [
      "L’humanité doit créer une forme d’économie différente, une économie circulaire dans laquelle les ressources, une fois prélevées, circulent pour être utilisées et réutilisées aussi longtemps que possible, dans laquelle il est mis un terme au gaspillage et à la pollution et dans laquelle des systèmes naturels sont régénérés.",
      "Pour les fournisseurs de service de restauration, les défis majeurs en matière de durabilité incluent les éléments suivants :"
    ],
    "list1" : [
      "Réduction du gaspillage alimentaire",
      "Création de processus qui contribuent à l’économie circulaire",
      "Réduction de l’empreinte carbone et de l’utilisation des ressources",
      "Réponse aux nouveaux modèles alimentaires notamment pour la livraison et la passation de commandes multicanales"
    ],
    "copy2" : [
      "Un tiers, voire plus, de la production alimentaire n’est jamais consommée.  Plus de la moitié de ce gaspillage intervient lors de la transformation, de la distribution et de la vente au détail.*  Sur une planète qui compte des centaines de millions de personnes souffrant de la faim, réduire le gaspillage constitue un impératif moral. Cet impératif bénéficie également à l’entreprise. En dépit des marges qui sont déjà très étroites, les épiceries, les commerces de proximité et les restaurants entament régulièrement leur marge de trois à quatre pour cent en acceptant que le gaspillage alimentaire soit intégré aux coûts de fonctionnement.* En outre, les politiques gouvernementales et les consommateurs exercent une pression croissante sur les marques, les restaurants, les détaillants et les producteurs pour instaurer la durabilité dans leurs chaînes d’approvisionnement.",
      "Les solutions intelligentes pour l’industrie alimentaire Freshmarx d’Avery Dennison offrent aux fournisseurs de services de restauration, les outils pour créer, mettre en place et maintenir des processus durables qui réduisent le gaspillage. Considérant non seulement les avantages environnementaux, mais également les économies réalisées tout au long de la chaîne d’approvisionnement, des pratiques durables inspirent la confiance des consommateurs, ce qui peut se traduire par une augmentation des ventes. ",
      "Pour en savoir plus sur l’importance de la création d’une économie circulaire dans la chaîne d’approvisionnement, contactez-nous au 0805.542.682 ou envoyez-nous un e-mail à l’adresse printersolutions@averydennison.com."
    ],
    "callouts" : [
      {
        "title" : "Benefits of Intelligent Labels RFID",
        "list" : [
          ["In the case of a food recall, the affected lot of food can accurately be traced and located to no more food than necessary is discarded", "1"],
          ["Track temperature throughout the shipping process to ensure product compliance", "2"],
          ["Manage accurate expiry time/dates for food safety", "3"],
          ["Prevent overstock or wrong stock in-store and efficiently manage inventory", "4"]
        ]
      },
      {
        "title" : "Benefits of Automated Solutions",
        "list" : [
          ["Accurate, legible prep labels to ensure food is used before it expires", "5"],
          ["Constant monitoring of refrigeration units to prevent food spoilage", "6"],
          ["Track food waste in the kitchen to target problem areas and save money", "7"],
          ["Quick, easy food donation and tracking for sustainability and tax benefits", "8"]
        ]
      }
    ],
    "copy3" : [
      "Food Foolish : The Hidden Connection Between Food Waste Hunger and Climate Change (Folie alimentaire : la relation cachée entre gaspillage alimentaire, faim dans le monde et réchauffement climatique)."
    ]
  },
  "zh" : {
    "imgAlt" : "Man with two children looking at plants",
    "title" : "循环经济的影响",
    "copy1" : [
      "本着人道主义，人类改变经济模式，即打造一种“循环”经济，也就是在取用资源后，要尽可能让资源经历使用和重复使用的循环，消除浪费和污染，自然系统由此得以再生。",
      "F对于食品服务提供商，最大的可持续性挑战包括："
    ],
    "list1" : [
      "减少食品浪费",
      "建立能够促进循环经济的流程",
      "降低碳足迹和资源的使用量",
      "响应新的食品模式，例如外卖和全渠道点餐"
    ],
    "copy2" : [
      "在全球生产的食品中，有三分之一甚至更多从未被人食用。其中一半以上的浪费发生在加工、配送和零售期间。*在这个数亿人挨饿的星球上，减少食品浪费是一种道德义务，也是一种有利于业务的举措。",
      "尽管利润已经及其微薄，但杂货店、便利店和餐厅仍然接受食品浪费作为营业成本的一部分，例行公事般丢掉 3% 到 4% 的利润。此外，政府政策和消费者越来越多地迫使品牌、餐厅、零售商和生产者在其供应链中建立可持续性。艾利丹尼森® Freshmarx® 智能食品行业解决方案向食品服务提供商赋予创建、实施和维护能够减少浪费的可持续流程所需的工具。可持续实践不仅带来环境效益，而且能够节省整个供应链的成本，从而促进消费者的信任，最终提高销售量。",
      "如需详细了解在食品供应链中创造循环经济的重要性，请通过 800.543.6650 或 printersolutions@averydennison.com 与我们联系。"
    ],
    "callouts" : [
      {
        "title" : "智能标签 RFID 的优势",
        "list" : [
          ["如果发生食品召回，厂商能够准确地追溯和找到受影响的食品批次，避免不必要地丢弃食品", "1"],
          ["跟踪整个装运流程中的温度，确保产品合规性", "2"],
          ["精准管理过期时间/日期，保障食品安全", "3"],
          ["防止店内存货积压或错误，高效管理库存", "4"]
        ]
      },
      {
        "title" : "Freshmarx 自动化解决方案的优势",
        "list" : [
          ["准确且清晰可辨的预备标签，确保在食品过期之前食用", "5"],
          ["持续监控制冷装置，防止食品腐败变质", "6"],
          ["跟踪厨房中的食品浪费，锁定问题区域并节省资金", "7"],
          ["快速轻松地捐赠和跟踪食品，由此带来可持续性提升和税收优惠", "8"]
        ]
      }
    ],
    "copy3" : [
      "*《Food Foolish: The Hidden Connection Between Food Waste Hunger and Climate Change》（食品的蠢事：食品浪费、饥饿与气候变化之间隐藏的联系）"
    ]
  },
  "gr" : {
    "imgAlt" : "Man with two children looking at plants",
    "title" : "Der Einfluss auf die Kreislaufwirtschaft",
    "copy1" : [
      "Die Menschheit muss eine andere Art von Wirtschaft erschaffen - eine „Kreislauf“-Wirtschaft, in der die gewonnenen Ressourcen so lange wie möglich dem Kreislauf von Nutzen und Wiedernutzen folgen, in dem Abfälle und Umweltverschmutzung vermieden werden und in dem sich natürliche Systeme regenerieren können.",
      "Für Lebensmittelhersteller gehören zu den größten Herausforderungen:"
    ],
    "list1" : [
      "Lebensmittelabfälle reduzieren",
      "Abläufe finden, die die Kreislaufwirtschaft fördern",
      "Den CO2-Fußabdruck und den Verbrauch von Ressourcen mindern",
      "Auf neue Lebensmittelmodelle, wie Essenslieferung und Omni-Channel-Bestellungen reagieren"
    ],
    "copy2" : [
      "Mindestens ein Drittel der produzierten Lebensmittel wird niemals gegessen. Mehr als die Hälfte dieses Abfalls entsteht während der Verarbeitung, der Verteilung und bei Einzelhändlern. Auf einem Planeten, der die Heimat von Hunderten von Millionen hungriger Menschen ist, ist die Reduzierung von Lebensmittelabfällen ein moralisches Gebot. Es ist darüber hinaus ein wirtschaftliches Gebot.",
      "Trotz der schon geringen Gewinnspanne werfen Lebensmittelhändler, Nachbarschaftsläden und Restaurants routinemäßig drei bis vier Prozent Ihrer Gewinnspanne weg, indem sie hinnehmen, dass Lebensmittelabfälle zu ihren Geschäftsausgaben zählen.* Darüber hinaus setzen Regierungspolitik und Verbraucher die Unternehmen, Restaurants, Einzelhändler und Produzenten immer mehr unter Druck, ihre Supply Chain nachhaltiger zu gestalten. Die intelligenten Lösungen für die Lebensmittelindustrie von Avery Dennison® Freshmarx® bieten Lebensmittellieferanten die Tools, um nachhaltige Prozesse zu schaffen, umzusetzen und aufrechtzuerhalten, die Abfälle reduzieren. Das ist nicht nur gut für die Umwelt, sondern spart außerdem in der gesamten Supply Chain Kosten ein. Nachhaltige Verpackungen erhöhen das Vertrauen der Verbraucher, was wiederum zu einem Anstieg der Verkaufszahlen führen kann.",
      "Wenn Sie weitere Informationen darüber erhalten möchten, wie wichtig eine Kreislaufwirtschaft in der Lebensmittel-Supply Chain ist, kontaktieren Sie uns bitte unter 800.543.6650 oder printersolutions@averydennison.com."
    ],
    "callouts" : [
      {
        "title" : "Vorteile von intelligenten RFID-Etiketten",
        "list" : [
          ["Im Falle eines Lebensmittelrückrufs, können die betroffenen Lebensmittel präzise verfolgt und lokalisiert werden, sodass nicht mehr Lebensmittel als unbedingt nötig im Abfall landen", "1"],
          ["Die Temperaturkontrolle ist im gesamten Lieferprozess möglich, um die Produktkonformität zu gewährleisten", "2"],
          ["Verfolgung des genauen Mindesthaltbarkeitsdatums/-zeit für mehr Lebensmittelsicherheit", "3"],
          ["Vermeidung von Überbeständen oder falschen Beständen in den Geschäften und effiziente Bestandsverwaltung", "4"]
        ]
      },
      {
        "title" : "Vorteile der automatisierten Lösungen von Freshmarx",
        "list" : [
          ["Akkurate, lesbare Etikette sorgen dafür, dass die Lebensmittel vor Ablauf verbraucht werden", "5"],
          ["Die konstante Überwachung der Kühlkette verhindert, dass Lebensmittel verderben", "6"],
          ["Mit der Kontrolle von Lebensmittelabfällen in der Küche bekämpft man Problembereiche gezielt und spart Geld", "7"],
          ["Schnelle und einfache Lebensmittelspenden und deren Nachverfolgung sorgen für mehr Nachhaltigkeit und steuerliche Vorteile", "8"]
        ]
      }
    ],
    "copy3" : [
      "Food Foolish „The Hidden Connection Between Food Waste Hunger and Climate Change.“"
    ]
  }
}
</i18n>