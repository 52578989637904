<template>
  <div>
    <picture>
      <source srcset="/assets/modals/food_resilience.webp" type="image/webp" media="(min-width: 700px)" />
      <source srcset="/assets/modals/food_resilience.jpg" type="image/jpeg" media="(min-width: 700px)" />
      <source srcset="/assets/modals/food_resilience_mobile.webp" type="image/webp" />
      <img class="img-fluid" src="/assets/modals/food_resilience_mobile.jpg" :alt="$t('imgAlt')">
    </picture>
    <div class="red_bg"></div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <h1 class="text-center">{{ $t('title') }}</h1>

          <h3 class="red">{{ $t('heading1') }}</h3>
          <p v-for="(item, index) in $t('copy1')" v-html="item"></p>
          
          <ul>
            <li v-for="(item, index) in $t('list1')" v-html="item"></li>
          </ul>

          <p v-for="(item, index) in $t('copy2')" v-html="item"></p>

          <h3 class="red">{{ $t('heading3') }}</h3>
          <p v-for="(item, index) in $t('copy3')" v-html="item"></p>

          <h3 class="red">{{ $t('heading4') }}</h3>
          <p v-for="(item, index) in $t('copy4')" v-html="item"></p>

          <h3 class="red">{{ $t('heading5') }}</h3>
          <p v-for="(item, index) in $t('copy5')" v-html="item"></p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TruePartnership'
}
</script>

<style lang="scss" scoped>
.modal picture {
  margin-top: -50px;
  display: block;
  img {
    display: block;
    width: 100%;
    max-width: none;
  }
}
.red_bg {
  padding: 25px 50px;
  margin-bottom: 50px;
}

ul {
  margin-bottom: 25px;
  margin-top: 20px;
}

h3 {
  margin-top: 30px;
}
</style>

<i18n>
{
  "en" : {
    "imgAlt" : "Men loading boxes into a truck",
    "title" : "True Partnership",
    "heading1" : "Free On-Site Analysis",
    "copy1" : [
      "Determining the right Solution for your business begins with a complimentary On-Site Analysis. Our consultants come on-site to your place of business (warehouse, restaurant, store, etc) and review your operations and identify process improvements. Our experts partner with you to examine each step of your everyday processes and operations and work with you to create a plan to:"
    ],
    "list1" : [
      "Increase labor efficiency",
      "Enable traceability and transparency",
      "Ensure product & food safety",
      "Create processes that promote sustainability",
      "Enhance consumer experience"
    ],
    "copy2" : [
      "<strong>How the analysis is done:</strong>",
      "1. Understand Your Goals - Our consultants will work with you to gain a thorough understanding of what you are striving to achieve.",
      "2. Understand Your Key Needs and Objectives -We want to understand your pain...what business challenges continue to put pressure on you.",
      "3. Conduct a Thorough Analysis of Your Business Location – Our team of experts can observe and analyze your current processes.",
      "4. Map Key Processes - We can provide you with flow charts of key processes.",
      "5. Develop Recommendations - Post-analysis we can meet with you to discuss our findings and propose recommendations."
    ],
    "heading3" : "RFID Design and Deployment",
    "copy3" : [
      "Our consultative services help you achieve the most efficient and productive RFID solutions custom-suited to your challenges.  Services include inventory management, systems integration and automation, logistics labeling, value-added solutions (VAS), reverse logistics and load optimization. ",
      "This helps to solve these challenges: labor efficiency, product & food safety, sustainability, customer experience."
    ],
    "heading4" : "System Integration",
    "copy4" : [
      "As part of our services, we do front-end consulting to understand your existing infrastructure.  With that knowledge, we can then determine how our existing system can best partner with our products and solutions for complete systems integration and optimum efficiency.",
      "This helps to solve for these challenges: Labor efficiency, food safety, sustainability, customer experience"
    ],
    "heading5" : "Service and Support",
    "copy5" : [
      "Our team provides the industry-best installation and training support, as well as maintenance and technical support for our products and third party printers such as Zebra, Sato, Datamax, and Novexx.  ",
      "Every product is fully supported by our Service team, the largest network of experienced, full-time bar-code and RFID technicians in the industry."
    ]
  },
  "fr" : {
    "imgAlt" : "Men loading boxes into a truck",
    "title" : "Véritable partenariat",
    "heading1" : "Analyse sur site gratuite",
    "copy1" : [
      "Le choix de la solution adaptée à votre activité commence par une analyse sur site complémentaire. Nos consultants se déplacent sur le site de votre activité professionnelle (entrepôt, restaurant, boutique, etc.) et examinent vos opérations afin d’identifier d’éventuelles améliorations du processus. Nos experts examinent avec vous chaque étape de vos processus et opérations au jour le jour et travaillent avec vous pour établir un plan visant à :"
    ],
    "list1" : [
      "Fournir un travail plus efficace",
      "Permettre la traçabilité et la transparence",
      "Garantir la sécurité alimentaire et celle des produits",
      "Créer des processus qui favorisent la durabilité",
      "Améliorer l’expérience du consommateur"
    ],
    "copy2" : [
      "Comment est réalisée l’analyse :",
      "1. Comprendre vos objectifs : nos consultants travailleront avec vous pour acquérir une compréhension approfondie de ce que vous tentez de mettre en place.",
      "2. Connaître vos besoins et objectifs essentiels : nous souhaitons identifier les points de dysfonctionnement de votre activité et vous aider à relever les défis continuant de représenter une source de pression pour vous.",
      "3. Effectuer une analyse précise de votre lieu de travail : notre équipe d’experts peut observer et analyser vos processus actuels.",
      "4. Cartographier vos processus clés : nous pouvons fournir des organigrammes des principaux processus.",
      "5. Formuler des recommandations : après l’analyse, nous pouvons nous rencontrer pour établir des conclusions et émettre des recommandations."
    ],
    "heading3" : "Conception RFID et déploiement",
    "copy3" : [
      "Nos services de consultation vous aident à mettre en place les solutions RFID les plus productives et les plus efficaces, adaptées aux défis que vous rencontrez.  Parmi les services proposés : gestion des stocks, automatisation et intégration des systèmes, étiquetage logistique, solutions à valeur ajoutée, optimisation de charge et logistique inverse.",
      "Ces services permettent de relever les défis suivants : efficacité de la main d’œuvre, sécurité alimentaire, sécurité des produits, durabilité et expérience client."
    ],
    "heading4" : "Intégration des systèmes",
    "copy4" : [
      "Dans le cadre de nos services, nous réalisons un conseil en amont afin de comprendre votre infrastructure existante.  Ceci nous permet ensuite de déterminer comment notre système existant peut s’associer au mieux à nos produits et solutions pour une intégration totale et une efficacité optimale. ",
      "Ainsi, nous pouvons relever les défis suivants : Efficacité de la main d’œuvre, sécurité alimentaire, durabilité, expérience client"
    ],
    "heading5" : "Maintenance et support",
    "copy5" : [
      "Notre équipe fournit un support de formation et une assistance sur les installations haut de gamme, ainsi qu’un support technique et de maintenance pour nos produits et imprimantes de tiers (Zebra, Sato, Datamax et Novexx). ",
      "Chaque produit Freshmarx et Monarch est entièrement pris en charge par notre équipe de maintenance, le plus vaste réseau de techniciens expérimentés et spécialisés dans les codes-barres et la RFID."
    ]
  },
  "zh" : {
    "imgAlt" : "Men loading boxes into a truck",
    "title" : "真正的合作伙伴关系",
    "heading1" : "免费的现场分析",
    "copy1" : [
      "从免费的现场分析开始，为您的业务确定正确的解决方案。我们派遣顾问前往您的业务场所现场（仓库、餐厅、商店等），审查您的运营情况并确定流程改进措施。我们的专家会与您合作，检查您的日常流程和运营情况，并与您共同制定计划，旨在："
    ],
    "list1" : [
      "提高劳动效率",
      "实现可追溯性和透明度",
      "确保产品和食品安全",
      "创建能够促进可持续性的流程",
      "增强消费者体验"
    ],
    "copy2" : [
      "<strong>进行分析的方式如下</strong>",
      "1. 了解您的长期目标 - 我们的顾问将与您合作，全面了解您要努力实现的目标。",
      "2. 了解您的关键需求和短期目标 - 我们希望了解您的痛点…哪些业务难题让您倍感压力。",
      "3. 对您的业务场地进行彻底的分析 - 我们的专家团队能够观察和分析您的当前流程。",
      "4. 绘制关键流程图 - 我们能够为您提供关键流程图。",
      "5. 提出建议 - 完成分析后，我们能够与您会面，以讨论分析结果并提出建议。"
    ],
    "heading3" : "RFID 设计和部署",
    "copy3" : [
      "我们的咨询服务将为您量身提供高效且富有成效的 RFID 解决方案，帮助您克服面临的挑战。服务项目包括库存管理、系统集成与自动化、物流标签、增值解决方案 (VAS)、逆向物流和负载优化。",
      "这有助于解决以下方面的诸多挑战：劳动效率、产品和食品安全、可持续性、客户体验。"
    ],
    "heading4" : "系统集成",
    "copy4" : [
      "作为服务项目的一部分，我们会进行前端咨询，以了解您的现有基础设施。在这些了解的基础上，我们能够确定现有系统怎样与我们的产品和解决方案充分配合，从而实现全面的系统集成并获得最佳效率。",
      "这有助于解决以下方面的诸多挑战：劳动效率、食品安全、可持续性、客户体验"
    ],
    "heading5" : "服务和支持",
    "copy5" : [
      "我们的团队为我们的产品以及 Zebra、Sato、Datamax 和 Novexx 等第三方打印机提供业内最佳的安全和培训支持，以及维护和技术支持。",
      "每一款 Freshmarx 和 Monarch 产品都享有我们服务团队，以及由业内经验丰富的全职条形码和 RFID 技术人员组成的最大网络的全面支持。"
    ]
  },
  "gr" : {
    "imgAlt" : "Men loading boxes into a truck",
    "title" : "Echte Partnerschaft",
    "heading1" : "Kostenlose Vor-Ort-Analyse",
    "copy1" : [
      "Anhand einer ergänzenden Vor-Ort-Analyse können Sie die richtige Lösung für Ihr Unternehmen finden. Unsere Berater kommen vor Ort in Ihr Unternehmen (Lager, Restaurant, Geschäft etc.), um Ihre Abläufe zu überprüfen und mögliche Prozessverbesserungen herauszufinden. Unsere Experten begutachten gemeinsam mit Ihnen die Schritte Ihrer täglichen Betriebsprozesse und -abläufe und erarbeiten gemeinsam mit Ihnen einen Plan mit folgenden Zielen:"
    ],
    "list1" : [
      "Steigerung der Arbeitseffizienz",
      "Gewährleistung von Rückverfolgbarkeit und Transparenz",
      "Gewährleistung von Produkt- & Lebensmittelsicherheit",
      "Ausarbeitung nachhaltiger Prozesse",
      "Verbesserung der Verbrauchererfahrung"
    ],
    "copy2" : [
      "<strong>Genauer Ablauf dieser Analyse:</strong>",
      "1. Ihre Ziele verstehen: Unsere Berater ermitteln gemeinsam mit Ihnen, was Sie hinsichtlich Ihrer Supply Chain erreichen möchten.",
      "2. Ihren Bedarfs und Ihre Ziele verstehen: Wir möchten Probleme analysieren und Herausforderungen erkennen, die Sie besonders fordern.",
      "3. Gründliche Analyse Ihres Geschäftsstandorts: Unser Expertenteam beobachtet und analysiert Ihre aktuellen Betriebsabläufe.",
      "4. Darstellung entscheidender Prozesse: Wir stellen Ihnen Ablaufdiagramme der entscheidenden Prozesse zur Verfügung.",
      "5. Entwicklung von Empfehlungen: Im Anschluss an die Analyse werden in einem Meeting die Ergebnisse und erarbeiteten Vorschläge besprochen."
    ],
    "heading3" : "RFID-Design und -Bereitstellung",
    "copy3" : [
      "Unsere Beratungsdienstleistungen helfen Ihnen, die effizientesten und produktivsten RFID-Lösungen zu finden, die auf Ihre Herausforderungen perfekt zugeschnitten sind. Zu den Dienstleistungen gehören Bestandsmanagement, Systemintegration und -automatisierung, logistische Etikettierung, Mehrwertlösungen (Value-added-Services, VAS), Rückwärtslogistik und Ladeoptimierung.",
      "Bei der Bewältigung der Herausforderungen helfen Arbeitseffizienz, Produkt- und Lebensmittelsicherheit, Nachhaltigkeit, Kundenerlebnis."
    ],
    "heading4" : "Systemintegration",
    "copy4" : [
      "Unsere Dienstleistungen umfassen Front-End-Beratung, damit wir Ihre vorhandene Infrastruktur verstehen. Dieses Wissen hilft uns, zu erkennen, wie unser vorhandenes System, am besten mit unseren Produkten und Lösungen kombiniert werden kann, damit Sie von einer vollständigen Systemintegration mit optimaler Effizienz profitieren können.",
      "Um diese Herausforderungen zu bewältigen, helfen: Arbeitseffizienz, Lebensmittelsicherheit, Nachhaltigkeit und Kundenerlebnis"
    ],
    "heading5" : "Service und Support",
    "copy5" : [
      "Unser Team bietet branchenweit die beste Einrichtung und Unterstützung durch Schulungen und außerdem Wartung und technischen Support für unsere Produkte und Drucker von Drittanbietern, wie Zebra, Sato, Datamax und Novexx.",
      "Für jedes Freshmarx and Monarch-Produkt steht unser Service-Team zur Verfügung, das branchenweit größte Netzwerk erfahrener Vollzeitspezialisten für die Bereiche Barcode und RFID."
    ]
  }
}
</i18n>